<template>
  <div class="recommendProduct">
    <div class="con-header">
      <div
        class="goback"
        @click="goBack"
      ></div>
      <div class="category">
        <div class="category-list">
          <div
            @click="category = 'standard';get_pro()"
            :class="[
              'category-block',
              category === 'standard' ? 'category-active' : '',
            ]"
          >
            <div>标体</div>
            <div
              v-show="category === 'standard'"
              class="row-line"
            ></div>
          </div>
          <div
            @click="category = 'exclude';get_pro()"
            :class="[
              'category-block',
              category === 'exclude' ? 'category-active' : '',
            ]"
          >
            <div>除外</div>
            <div
              v-show="category === 'exclude'"
              class="row-line"
            ></div>
          </div>
          <div
            @click="category = 'artificial';get_pro()"
            :class="[
              'category-block',
              category === 'artificial' ? 'category-active' : '',
            ]"
          >
            <div>人核</div>
            <div
              v-show="category === 'artificial'"
              class="row-line"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="con-body"
      v-if="products.length>0"
    >
      <div
        class="product-item"
        v-for="(item,index) in products"
        :key="index"
      >
        <div class="pro-title">
          <div class="pro-name">{{item.itemTitle}}</div>
          <div class="pro-tab">{{item.productDesc}}</div>
          <div class="pro-tab">{{item.online}}</div>
        </div>
        <div class="pro-info">
          <div class="info-msg">承保公司：<span>{{item.company}}</span></div>
          <div class="info-msg">投保年龄：<span>{{item.fitperson}}</span></div>
          <div class="info-msg">BMI要求：<span>{{item.bmi}}</span></div>
        </div>
      </div>
    </div>
    <div
      class="noData"
      v-if="noData"
    >
      <p class="noDataImg"></p>
      <p class="noDataText">没有匹配的产品</p>
    </div>
  </div>
</template>

<script>
import { recommendProduct } from "@/fetch/api/chat.js";
export default {
  components: {},
  data() {
    return {
      category: "standard",
      id: this.$route.params.id,
      products: [],
      noData: false,
    };
  },
  mounted() {
    this.get_pro();
  },
  computed: {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    get_pro() {
      this.products = [];
      recommendProduct({ id: this.id, category: this.category })
        .then((result) => {
          this.products = result.products;
          this.noData = result.products.length > 0 ? false : true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.clear {
  clear: both;
}
.recommendProduct {
  width: 100%;
  text-align: center;
  background: #f5f5f5;
  position: absolute;
  min-height: 100%;
}
.con-header {
  background: #ffffff;
}
.goback {
  width: 0.56rem;
  height: 0.76rem;
  background: url("../../assets/images/back.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 0.4rem;
  top: 0.3rem;
}

.category {
  width: 7.5rem;
  display: inline-block;
}
.category-list {
  height: 1.28rem;
  line-height: 1.28rem;
  display: flex;
}
.category-block {
  flex: 1;
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  text-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  position: relative;
}
.row-line {
  border: 1px solid #1b78f6;
  border-radius: 1px;
  width: 1rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.category-active {
  font-size: 0.48rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #1977f6;
  text-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
}
.con-body {
  min-height: 19.36rem;
  background: #f5f5f5;
  padding: 0.4rem;
}
.product-item {
  min-height: 3.173333rem;
  width: 9.2rem;
  padding: 0.4rem;
  background: #ffffff;
  margin-bottom: 0.4rem;
  box-shadow: 0px -1px 0.4rem 0px rgba(0, 0, 0, 0.03);
  border-radius: 0.266667rem;
}
.pro-name {
  float: left;
  max-width: 5.6rem;
  text-align: left;
  font-size: 0.453333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #000000;
  font-weight: 500;
}
.pro-tab {
  float: right;
  width: 1.226667rem;
  height: 0.533333rem;
  background: #eaf2fb;
  border-radius: 0.266667rem;
  font-size: 0.346667rem;
  line-height: 0.533333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #5b93f8;
  font-weight: 400;
  margin: 0.053333rem;
}
.pro-info {
  font-size: 0.346667rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  clear: both;
  padding-top: 0.16rem;
}
.info-msg {
  text-align: left;
  // height: 15px;
  margin-bottom: 0.16rem;
}
.noData {
  width: 100%;
  padding-top: 2.61rem;
  .noDataImg {
    margin: 0 auto;
    width: 5.39rem;
    height: 4.08rem;
    background: url("../../assets/images/noData.png") no-repeat;
    background-size: 100% 100%;
  }
  .noDataText {
    font-size: 0.35rem;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: #999999;
    margin-top: 0.24rem;
  }
}
</style>
